import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { slotToTime, slotToQuarterTime } from '../utils/converters';
import { formatDateToGMT2, slotToDate, isSameDay } from '../utils/timeslot';

const Timeslots = ({ selectedDate, slotData, guests, setSelectedSlot, language, clearSelectedSlot }) => {
  const [localSelectedSlot, setLocalSelectedSlot] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [availableIntervals, setAvailableIntervals] = useState([]);
  const [displayedIntervals, setDisplayedIntervals] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);

  useEffect(() => {
    if (window.shift) {
      setShiftData(window.shift);
    } else {
      setShiftData([]);
    }
  }, []);

  useEffect(() => {
    setSelectedInterval(null);
    if (selectedDate) {
      if (window.capacityPerShift) {
        return updateAvailableIntervalsShifts();
      }
      else {
        updateAvailableIntervals();
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    if (availableIntervals.length > 0) {
      const checkIntervals = availableIntervals.filter(interval => {

        let filteredSlots;

        if (window.capacityPerShift) {
          filteredSlots = filterSlotsByIntervalShift(interval);
        }
        else {
          filteredSlots = filterSlotsByInterval(interval);
        }
        return filteredSlots.length > 0;
      });
      setDisplayedIntervals(checkIntervals);
    }
  }, [availableIntervals]);

  const shiftLength = window.shiftLength || 4;

  const slotToInterval = {
    14: 'M', 15: 'M', 16: 'M', 17: 'M', 18: 'M', 19: 'M', 20: 'M', 21: 'M', 22: 'M', 23: 'M',
    24: 'A', 25: 'A', 26: 'A', 27: 'A', 28: 'A', 29: 'A', 30: 'A', 31: 'A', 32: 'A', 33: 'A',
    34: 'E', 35: 'E', 36: 'E', 37: 'E', 38: 'E', 39: 'E', 40: 'E', 41: 'E', 42: 'E', 43: 'E',
    44: 'E', 45: 'E', 46: 'E', 47: 'E'
  };
  
  const updateAvailableIntervalsShifts = () => {
    const formattedDate = formatDateToGMT2(selectedDate);
    const timeslots = window.slots_data[formattedDate]["bookings"] || {};

    console.log(timeslots);
    const intervals = {
      Night: true,
      Morning: window.booking_data[formattedDate]["M"] + guests <= window.capacity_data[formattedDate]["M"],
      Afternoon: window.booking_data[formattedDate]["A"] + guests <= window.capacity_data[formattedDate]["A"],
      Evening: window.booking_data[formattedDate]["E"] + guests <= window.capacity_data[formattedDate]["E"],
    };
  
    const filteredIntervals = Object.keys(intervals).filter(interval => intervals[interval]);
    setAvailableIntervals(filteredIntervals);
  
    const availableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const interval = slotToInterval[slot];
        const bookingCount = window.booking_data[formattedDate][interval];
        const capacity = window.capacity_data[formattedDate][interval];
        const isSlotAvailable = bookingCount + guests <= capacity;
    
        return isSlotAvailable;
      })
      .map(Number);
  
    setFilteredSlots(availableTimeslots);
  };

  const updateAvailableIntervals = () => {
    const formattedDate = formatDateToGMT2(selectedDate);
    const timeslots = slotData[formattedDate] || {};

    const availableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const isToday = isSameDay(selectedDate, new Date());
        let isSlotValid = timeslots[slot] >= guests;

        if (isToday) {
          const slotTime = slotToDate(slot, selectedDate);
          const currentTimePlus4Hours = new Date();
          currentTimePlus4Hours.setHours(currentTimePlus4Hours.getHours() + shiftLength);
          return isSlotValid && slotTime >= currentTimePlus4Hours;
        }
        return isSlotValid;
      })
      .map(Number);

    const checkConsecutiveSlots = (startSlot) => {
      for (let i = 0; i < shiftLength; i++) {
        if (!availableTimeslots.includes(startSlot + i)) {
          return false;
        }
      }
      return true;
    };

    const intervals = {
      Night: availableTimeslots.some(slot => slot >= 1 && slot < 14 && checkConsecutiveSlots(slot)),
      Morning: availableTimeslots.some(slot => slot >= 14 && slot < 25 && checkConsecutiveSlots(slot)),
      Afternoon: availableTimeslots.some(slot => slot >= 25 && slot < 34 && checkConsecutiveSlots(slot)),
      Evening: availableTimeslots.some(slot => slot >= 34 && slot <= 47 && checkConsecutiveSlots(slot)),
    };

    const filteredIntervals = Object.keys(intervals).filter(interval => intervals[interval]);
    setAvailableIntervals(filteredIntervals);

    if (shiftData.length > 0 && window.validKey) {
      const allowedSlots = shiftData.map(shift => shift.number);
      setFilteredSlots(availableTimeslots.filter(slot => allowedSlots.includes(slot)));
    } else {
      setFilteredSlots(availableTimeslots);
    }
  };

  const filterSlotsByIntervalShift = (interval) => {
    let filteredSlots = [];  
    const formattedDate = formatDateToGMT2(selectedDate);  
    const timeslots = window.slots_data[formattedDate]["bookings"] || {};
  
    const availableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const isToday = isSameDay(selectedDate, new Date());
        let isSlotValid = window.slots_data[formattedDate]["bookings"][slot] + guests <= window.slots_data[formattedDate]["capacity"][slot];
  
        if (isToday) {
          const slotTime = slotToDate(slot, selectedDate);
          const currentTimePlus4Hours = new Date();
          currentTimePlus4Hours.setHours(currentTimePlus4Hours.getHours() + shiftLength);
  
          const isValidTodaySlot = isSlotValid && slotTime >= currentTimePlus4Hours;
          return isValidTodaySlot;
        }
        return isSlotValid;
      })
      .map(Number);
  
  
    switch (interval) {
      case 'Morning':
        filteredSlots = availableTimeslots.filter(slot => slot >= 14 && slot < 24);
        break;
      case 'Afternoon':
        filteredSlots = availableTimeslots.filter(slot => slot >= 24 && slot < 35);
        break;
      case 'Evening':
        filteredSlots = availableTimeslots.filter(slot => slot >= 35 && slot <= 47);
        break;
      case 'Night':
        filteredSlots = availableTimeslots.filter(slot => slot >= 1 && slot < 14);
        break;
      default:
        filteredSlots = availableTimeslots;
    }
  
    if (shiftData.length > 0) {
      const allowedSlots = shiftData.map(shift => shift.number);
      filteredSlots = filteredSlots.filter(slot => allowedSlots.includes(slot));
    }
  
    return filteredSlots;
  };  

  const filterSlotsByInterval = (interval) => {
    let filteredSlots = [];  
    const formattedDate = formatDateToGMT2(selectedDate);
  
    const timeslots = slotData[formattedDate] || {};  
    const availableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const isToday = isSameDay(selectedDate, new Date());
        let isSlotValid = timeslots[slot] >= guests;
  
        if (isToday) {
          const slotTime = slotToDate(slot, selectedDate);
          const currentTimePlus4Hours = new Date();
          currentTimePlus4Hours.setHours(currentTimePlus4Hours.getHours() + shiftLength);  
          const isValidTodaySlot = isSlotValid && slotTime >= currentTimePlus4Hours;
          return isValidTodaySlot;
        }
        return isSlotValid;
      })
      .map(Number);
    
    const checkConsecutiveSlots = (startSlot) => {
      for (let i = 0; i < shiftLength; i++) {
        if (!availableTimeslots.includes(startSlot + i)) {
          return false;
        }
      }
      return true;
    };
  
    switch (interval) {
      case 'Morning':
        filteredSlots = availableTimeslots.filter(slot => slot >= 14 && slot < 24 && checkConsecutiveSlots(slot));
        break;
      case 'Afternoon':
        filteredSlots = availableTimeslots.filter(slot => slot >= 24 && slot < 35 && checkConsecutiveSlots(slot));
        break;
      case 'Evening':
        filteredSlots = availableTimeslots.filter(slot => slot >= 35 && slot <= 47 && checkConsecutiveSlots(slot));
        break;
      case 'Night':
        filteredSlots = availableTimeslots.filter(slot => slot >= 1 && slot < 14 && checkConsecutiveSlots(slot));
        break;
      default:
        filteredSlots = availableTimeslots.filter(slot => checkConsecutiveSlots(slot));
    }
  
    if (shiftData.length > 0) {
      const allowedSlots = shiftData.map(shift => shift.number);
      filteredSlots = filteredSlots.filter(slot => allowedSlots.includes(slot));
    }
  
    return filteredSlots;
  };
  

  const handleSlotSelect = (slot, isQuarter) => {
    window.waitingListSelection = false;
    setLocalSelectedSlot(slot);
    setSelectedSlot(slot, isQuarter);

    const shiftDescription = shiftData.find(shift => shift.number === slot)?.description || '';
    if (shiftDescription) {
      localStorage.setItem('shiftDescription', shiftDescription);
    } else {
      localStorage.removeItem('shiftDescription');
    }
  };

  const handleIntervalSelect = (interval) => {
    setSelectedInterval(interval);
  };

  if (!selectedDate) {
    return (
      <div className="timeslot-buttons">
        <Button className="m-1 timeslot-button">
          {language === 'NL' ? 'Kies een dag' : language === 'FR' ? 'Sélectionnez un jour' : 'Select a day'}
        </Button>
      </div>
    );
  }

  if (!selectedInterval) {
    return (
      <div className="timeslot-buttons">
        {displayedIntervals.map((interval, index) => (
          <Button key={index} className="m-1 timeslot-button" onClick={() => handleIntervalSelect(interval)}>
            {language === 'NL' ? interval === 'Night' ? 'Nacht' : interval === 'Morning' ? 'Ochtend' : interval === 'Afternoon' ? 'Middag' : 'Avond' : language === 'FR' ? interval === 'Night' ? 'Nuit' : interval === 'Morning' ? 'Matin' : interval === 'Afternoon' ? 'Après-midi' : 'Soir' : interval}
          </Button>
        ))}
      </div>
    );
  }

  let slotsToDisplay;
  
  if (window.capacityPerShift) {
    slotsToDisplay = shiftData.length > 0 ? filteredSlots : filterSlotsByIntervalShift(selectedInterval);
  }
  else {
    slotsToDisplay = shiftData.length > 0 ? filteredSlots : filterSlotsByInterval(selectedInterval);
  }
  const isShiftDataEmpty = shiftData.length === 0;

  if (slotsToDisplay.length > 1 && isShiftDataEmpty) {
    slotsToDisplay = slotsToDisplay.slice(0, -1);
  }

  return (
    <div className="timeslot-buttons">
      {slotsToDisplay.map((slot, index) => {
        const shiftDescription = shiftData.find(shift => shift.number === slot)?.description || '';
        const isQuarter = index % 2 !== 0;
        const isLastSlot = index === slotsToDisplay.length - 1;

        return (
          <Row key={index} className="mb-2">
            <Col xs={isShiftDataEmpty ? 6 : 12}>
              <Button
                variant={localSelectedSlot === slot ? "primary" : "outline-primary"}
                className="m-1 timeslot-button"
                onClick={() => handleSlotSelect(slot, false)}
              >
                {`${shiftDescription ? `${shiftDescription}` : `${slotToTime(Number(slot))}`}`}
              </Button>
            </Col>
            {!isLastSlot && isShiftDataEmpty && (
              <Col xs={6}>
                <Button
                  variant={localSelectedSlot === slot ? "primary" : "outline-primary"}
                  className="m-1 timeslot-button"
                  onClick={() => handleSlotSelect(slot, true)}
                >
                  {`${shiftDescription ? `${shiftDescription}` : `${slotToQuarterTime(Number(slot))}`}`}
                </Button>
              </Col>
            )}
          </Row>
        );
      })}
    </div>
  );
};

export default Timeslots;
